<template>
  <b-col md="10">
    <b-card-group class="align-items-center bg-white h-100">
      <b-card no-body class="border-0 rounded-0">
        <b-card-body class="pt-0">
          <b-form
            class="verify-code-input pt-2"
            novalidate
            @submit.prevent="onSubmitRegister"
          >
            <h5 class="text-center mb-4">
              <b>ご登録のメールアドレスに送られた認証番号をご入力ください</b>
            </h5>
            <transition-alert>
              <template v-if="msgErrors.length">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="danger"
                >
                  <span
                    class="d-block"
                    v-for="(msg, index) in msgErrors"
                    :key="index"
                    >{{ msg }}</span
                  >
                </b-alert>
              </template>
            </transition-alert>
            <transition-alert>
              <template v-if="msgSuccess">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="success"
                >
                  {{ msgSuccess }}
                </b-alert>
              </template>
            </transition-alert>
            <b-input-group class="mb-1 mb-md-0">
              <div class="group-email py-3 pb-md-4 mx-auto">
                <v-otp-input
                  ref="otpInput"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="LENGTH_NUM_CODE"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  input-type="number"
                  @on-complete="handleOnComplete"
                  @on-change="handleOnChange($event)"
                />
              </div>
            </b-input-group>

            <p class="text-center d-m-flex">
              <b-button
                @click="resetOtp"
                :disabled="isReset"
                class="my-2 px-4 my-2 rounded-0 btn-darklight"
                >{{ $t("general_inputs.btn_resend_otp") }}
                <esports-loading-button
                  v-if="isReset"
                  width="30px"
                  height="30px"
                  outerBorder="#333"
                  innerBorder="#eee"
              /></b-button>
              <b-link
                @click="handleClearInput"
                class="btn btn-secondary rounded-0 px-4 mx-3 my-2"
                >{{ $t("general_inputs.btn_clear") }}</b-link
              >
              <b-button
                class="primary px-4 my-2 rounded-0"
                type="submit"
                :disabled="isSubmitted || !formRegister.otp"
                >{{ $t("general_inputs.btn_submit") }}
                <esports-loading-button
                  v-if="isSubmitted"
                  width="30px"
                  height="30px"
                  outerBorder="#333"
                  innerBorder="#eee"
              /></b-button>
            </p>
          </b-form>
        </b-card-body>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
import { dismissCount } from "@/mixins";
export default {
  name: "VerifyCode",
  props: {
    formRegisterData: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitted: false,
      isReset: false,
      msgSuccess: null,
      msgErrors: [],
      LENGTH_NUM_CODE: 6,
      formRegister: {
        otp: "",
      },
    };
  },
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mixins: [dismissCount],
  mounted() {
    document.addEventListener('keypress', async (e) => {
      if(e.keyCode === 13 && this.formRegister?.otp.length === this.LENGTH_NUM_CODE) {
        await this.onSubmitRegister();
      }
    });
  },
  methods: {
    async onSubmitRegister() {
      this.isSubmitted = true;
      this.msgSuccess = null;
      this.formRegister.email = this.formRegisterData.email;

      const result = await this.$store
        .dispatch("auth/checkOtp", this.formRegister)
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      this.isSubmitted = false;

      if (result) {
        this.msgErrors = [];
        this.formRegister.otpCode = result.otpCode;
        delete this.formRegister.otp;
        this.$emit("activeComponent", {
          user: this.formRegister,
          name: "RegisterEnterInfoUser",
        });
      }
    },
    async resetOtp() {
      this.isReset = true;
      this.msgSuccess = null;
      
      const result = await this.$store
        .dispatch("auth/resetOtp", { email: this.formRegisterData.email })
        .catch((err) => {
          this.isReset = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });
      this.isReset = false;
      if (result) {
        this.msgSuccess = result.message;
      }
    },
    handleOnComplete(value) {
      this.formRegister.otp = value;

      this.$nextTick(() => {
        this.$refs['otpInput'].$el.blur();
        document.querySelectorAll('.otp-input')[5].blur();
      })
    },
    handleOnChange(value) {
      if (value === this.LENGTH_NUM_CODE) {
        this.formRegister.otp = value;
        return;
      }
      this.formRegister.otp = "";
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
      this.formRegister.email = this.formRegisterData.email;
      this.$emit("activeComponent", {
          user: this.formRegister,
          name: "RegisterByEmail",
      });
    },
  },
};
</script>