<template>
  <b-col md="8" lg="6" xl="4">
    <b-card-group class="align-items-center h-100">
      <b-card no-body class="p-4 border-0 rounded-0 card-400">
        <b-card-body class="p-0 mb-5">
          <h3 class="title pb-md-3 text-center">
            {{ $t("general_inputs.member_signup") }}
          </h3>

          <esports-line-text text="SNSアカウントで登録" />
          <SignupSocialNetwork @activeComponent="activeComponent" />
          <esports-line-text text="メールアドレスで登録" />

          <b-form
            novalidate
            @submit.prevent="onSubmitRegister"
            class="pt-4 form-inputs"
          >
            <transition-alert>
              <template v-if="msgErrors.length">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="danger"
                >
                  <span
                    class="d-block"
                    v-for="(msg, index) in msgErrors"
                    :key="index"
                    >{{ msg }}</span
                  >
                </b-alert>
              </template>
            </transition-alert>
            <transition-alert>
              <template v-if="msgSuccess">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="success"
                >
                  {{ msgSuccess }}
                </b-alert>
              </template>
            </transition-alert>
            <b-input-group>
              <b-form-input
                class="text-truncate"
                type="email"
                :placeholder="$t('general_inputs.email_register')"
                maxLength="200"
                v-model="formRegister.email"
                autocomplete="off"
              />
            </b-input-group>
            <b-button
              block
              class="primary px-4"
              type="submit"
              :disabled="isSubmitted"
              >{{ $t("general_inputs.btn_register") }}
              <esports-loading-button
                v-if="isSubmitted"
                width="30px"
                height="30px"
                outerBorder="#333"
                innerBorder="#eee"
            /></b-button>
          </b-form>
        </b-card-body>
        <b-card-footer class="pt-4 px-0">
          <div class="d-flex justify-content-center align-items-center">
            <span class="pr-md-4"
              >{{ $t("general_inputs.txt_account_register") }} &nbsp;</span
            >
            <b-link
              class="btn btn-outline-primary px-xl-5 rounded-pill"
              to="/login"
              >{{ $t("general_inputs.txt_login") }}</b-link
            >
          </div>
        </b-card-footer>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
import SignupSocialNetwork from "@/components/Shared/SignupSocialNetwork";
import { dismissCount } from "@/mixins";
export default {
  name: "RegisterByEmail",
  components: { SignupSocialNetwork },
  props: {
    formRegisterData: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitted: false,
      msgErrors: [],
      msgSuccess: null,
      formRegister: {
        email: "",
      },
    };
  },
  watch: {
    msgErrors(nVal) {
      if (nVal.length) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mounted() {
    this.formRegister = { ...this.formRegisterData };
  },
  mixins: [dismissCount],
  methods: {
    async onSubmitRegister() {
      this.isSubmitted = true;
      const result = await this.$store
        .dispatch("auth/verifyEmail", this.formRegister)
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      this.isSubmitted = false;

      if (result) {
        this.msgSuccess = result.message;
        this.msgErrors = [];
        setTimeout(() => {
          this.$emit("activeComponent", {
            user: this.formRegister,
            name: "VerifyCode",
          });
        }, 1500);
      }
    },
    activeComponent(payload) {
      this.$emit("activeComponent", {
        user: payload.user,
        name: payload.name,
      });
    },
  },
};
</script>