<template>
  <b-col md="12" lg="8" xl="6">
    <b-card-group class="align-items-center bg-white h-100">
      <b-card no-body class="border-0 rounded-0">
        <b-card-body class="pt-0 card-body-info">
          <b-form
            class="register-email info-user form-inputs"
            novalidate
            @submit.prevent="onSubmitRegister"
          >
            <h3 class="title pb-md-3 pb-xxl-5 text-center">
              <span>{{ $t("general_inputs.member_info") }}</span> <br />
              <span class="sub-title">下記内容でお間違いがなければ登録ボタンを押してください。</span>
            </h3>

            <b-input-group class="mb-1 mb-md-0">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0"
                  ><span class="pr-2 pr-lg-3 pt-1">{{
                    $t("general_inputs.username")
                  }}</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email">
                <b-form-input
                  class="border-0 rounded-0 pl-0"
                  type="text"
                  disabled
                  v-model="formRegister.username"
                  autocomplete="off"
                />
              </div>
            </b-input-group>
            <b-input-group class="mb-1 mb-md-0 align-items-baseline">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0"
                  ><span class="pr-2 pr-lg-4">{{
                    $t("general_inputs.password")
                  }}</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email group-password">
                <span class="txt-password">{{ formRegister.password }}</span>
                <span class="txt-hint">（セキュリティ上、非公開)</span>
              </div>
            </b-input-group>

            <p class="text-center d-m-flex pt-5 mt-5">
              <b-link class="btn btn-outline-secondary" to="/">{{
                $t("general_inputs.btn_return")
              }}</b-link>
              <b-button
                class="primary px-4"
                type="submit"
                :disabled="isSubmitted"
                >{{ $t("general_inputs.btn_submit") }}
                <esports-loading-button
                  v-if="isSubmitted"
                  width="30px"
                  height="30px"
                  outerBorder="#333"
                  innerBorder="#eee"
              /></b-button>
            </p>
          </b-form>
        </b-card-body>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
export default {
  name: "RegisterInfoUser",
  props: {
    formRegisterData: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      type: "password",
      isSubmitted: false,
      formRegister: {
        email: "",
        password: "",
        username: "",
        isRegister: true
      },
    };
  },
  mounted() {
    this.formRegister = { ...this.formRegisterData };
  },
  methods: {
    async onSubmitRegister() {
      if (this.formRegister.password) delete this.formRegister.password;
      if (this.formRegister.passwordConfirmed)
        delete this.formRegister.passwordConfirmed;
      if (this.formRegister.otpCode) delete this.formRegister.otpCode;

      this.$store.commit("auth/storeUser", this.formRegister);
      this.$store.commit("auth/setTokenUser", this.formRegister);

      this.$emit("activeComponent", {
        user: this.formRegister,
        name: "NotifyVerifyUser",
      });
    },
  },
};
</script>