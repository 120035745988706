<template>
  <b-col md="12" lg="8" xl="6">
    <b-card-group class="align-items-center bg-white h-100">
      <b-card no-body class="border-0 rounded-0">
        <b-card-body class="pt-0 card-body-info">
          <b-form
            class="register-email form-inputs"
            novalidate
            @submit.prevent="onSubmitRegister"
          >
            <h3 class="title pb-md-3 pb-xxl-5 text-center">
              {{ $t("general_inputs.member_info") }}
            </h3>
            <transition-alert>
              <template v-if="msgErrors.length">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="danger"
                >
                  <span
                    class="d-block"
                    v-for="(msg, index) in msgErrors"
                    :key="index"
                    >{{ msg }}</span
                  >
                </b-alert>
              </template>
            </transition-alert>
            <transition-alert>
              <template v-if="msgSuccess">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="success"
                >
                  {{ msgSuccess }}
                </b-alert>
              </template>
            </transition-alert>

            <b-input-group class="mb-1 mb-md-0 mt-3 input-group-info mt-md-0">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0"
                  ><span class="pr-2 pr-lg-3 pt-1">{{
                    $t("general_inputs.username")
                  }}</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email">
                <b-form-input
                  class="text-truncate"
                  type="text"
                  maxLength="60"
                  v-model="formRegister.username"
                  autocomplete="off"
                />
                <span class="chars">{{
                  $t("general_inputs.hint_email_2")
                }}</span>
              </div>
            </b-input-group>
            <b-input-group class="mb-4 pb-2 pb-md-0 mb-md-0 input-group-info align-items-baseline">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0"
                  ><span class="pr-2 pr-lg-4">{{
                    $t("general_inputs.password")
                  }}</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email group-password">
                <b-form-input
                  class="text-truncate"
                  type="password"
                  v-model="formRegister.password"
                  minLength="8"
                  maxLength="32"
                  autocomplete="off"
                />
                <div class="confirm-input-password">
                  <a v-b-toggle href="#confirm-collapse" @click.prevent
                    ><span>▼</span>
                    {{ $t("general_inputs.please_confirm_pass") }}</a
                  >
                  <b-collapse id="confirm-collapse" class="mt-1" visible>
                    <template>
                      <b-form-input
                        class="text-truncate"
                        type="password"
                        v-model="formRegister.passwordConfirmed"
                        minLength="8"
                        maxLength="32"
                        autocomplete="off"
                      />
                    </template>
                  </b-collapse>
                </div>
                <span class="chars">{{
                  $t("general_inputs.rule_password")
                }}</span>
              </div>
            </b-input-group>

            <p class="text-right d-m-flex pt-3 mt-2">
              <b-link class="btn btn-outline-secondary" to="/">{{
                $t("general_inputs.btn_cancel")
              }}</b-link>
              <b-button
                class="primary px-4"
                type="submit"
                :disabled="isSubmitted"
                >{{ $t("general_inputs.btn_submit") }}
                <esports-loading-button
                  v-if="isSubmitted"
                  width="30px"
                  height="30px"
                  outerBorder="#333"
                  innerBorder="#eee"
              /></b-button>
            </p>
          </b-form>
        </b-card-body>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
import { dismissCount } from "@/mixins";
import { mapState } from "vuex";

export default {
  name: "RegisterEnterInfoUser",
  props: {
    formRegisterData: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitted: false,
      msgErrors: [],
      msgSuccess: null,
      formRegister: {
        email: "",
        username: "",
        password: "",
        passwordConfirmed: "",
        loginPlatform: 1,
        isRegister: true
      },
    };
  },
  computed: {
    ...mapState("auth", ["deviceId"]),
  },
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mixins: [dismissCount],
  mounted() {
    this.formRegister = { ...this.formRegisterData };
  },
  methods: {
    async onSubmitRegister() {

      this.isSubmitted = true;
      this.formRegister.loginProvider = 0;
      this.formRegister.deviceId = this.deviceId;
      this.formRegister.loginPlatform = 1;

      const result = await this.$store
        .dispatch("auth/signup", this.formRegister)
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      this.isSubmitted = false;

      if (result) {
        this.formRegister.accessToken = result.accessToken;
        this.formRegister.refreshToken = result.refreshToken;
        this.formRegister.password = Array(this.formRegister.password + 1).join("*");
        this.formRegister.passwordConfirmed = Array(this.formRegister.passwordConfirmed + 1).join("*");

        if(result.isInvited) {
          this.$store.commit("auth/setUpModalTicketInvite", true);
          this.$store.commit("auth/setNumberTicketInvited", result.numberTicketsInvited);
        }
        
        this.$store.commit("auth/storeUser", this.formRegister);
        this.$store.commit("auth/setTokenUser", this.formRegister);
        this.$store.commit("auth/setNumberTicketRegister", result.numberTicketsRegister);

        this.msgErrors = [];

        this.$emit("activeComponent", {
          user: this.formRegister,
          name: "NotifyVerifyUser",
        });

        // this.$router.push({path: '/home'});
      }
    },
  },
};
</script>