<template>
  <div class="app page-bg">
    <esports-header-authen />
    <b-container
      class="login-page register-page pb-5"
      :class="[{ 'verify-code-page': isVerifyCode }]"
    >
      <b-row class="justify-content-center w-100">
        <component
          @activeComponent="activeComponent"
          :formRegisterData="formRegisterData"
          :is="currentTabComponent"
        ></component>
      </b-row>
    </b-container>
    <esports-footer-authen />
  </div>
</template>

<script>
/* eslint-disable */
import RegisterByEmail from "./Register/RegisterByEmail";
import VerifyCode from "./Register/VerifyCode";
import NotifyVerifyCode from "./Register/NotifyVerifyCode";
import NotifyVerifyUser from "./Register/NotifyVerifyUser";
import RegisterEnterInfoUser from "./Register/RegisterEnterInfoUser";
import RegisterInfoUser from "./Register/RegisterInfoUser";
import RegisterEnterUserNameSocial from "./Register/RegisterEnterUserNameSocial";

export default {
  name: "RegisterPage",
  title: "register",
  components: {
    RegisterByEmail,
    VerifyCode,
    NotifyVerifyCode,
    RegisterEnterInfoUser,
    RegisterInfoUser,
    NotifyVerifyUser,
    RegisterEnterUserNameSocial,
  },
  data() {
    return {
      isSubmitted: false,
      formRegisterData: null,
      isVerifyCode: false,
      currentTabComponent: RegisterByEmail,
    };
  },
  methods: {
    activeComponent(payload) {
      this.formRegisterData = payload.user;
      this.currentTabComponent = payload.name;

      if (
        payload.name === "VerifyCode" ||
        payload.name === "NotifyVerifyCode" ||
        payload.name === "RegisterEnterUserNameSocial"
      ) {
        this.isVerifyCode = true;
        return;
      }
      this.isVerifyCode = false;
    },
  },
};
</script>