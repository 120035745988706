<template>
  <b-col md="10" lg="8">
    <b-card-group class="align-items-center h-100 verify-code">
      <b-card no-body class="border-0 rounded-0">
        <b-card-body class="py-0 px-2 px-lg-5 mt-3">
          <b-form
            class="p-3 p-lg-5 mb-lg-4"
            novalidate
          >
            <h5 class="text-center mb-0 mb-md-3">
              <b>下記のメールアドレス宛に、認証番号を送信致しました。ご確認くださいませ</b>
            </h5>
            <p class="text-center text-center mt-4 mt-md-5 mb-4 py-1 pb-2"><esports-icon-down /></p>
            <h4 class="text-center" v-if="formRegisterData && formRegisterData.email">{{ formRegisterData.email }}</h4>
            <div class="content-text p-3">
              <p>
                ご入力いただいたメールアドレスに受付完了について記載されたメールが送信されます。<br />
                なお、上記の内容のメールが届かない場合は「info@xxxxxxxxx.com」よりご連絡ください。
              </p>
              <ul>
                <li>
                  フリーメールアドレスでのご登録は、登録完了手続きメールの到着が遅れる場合がございます。
                </li>
                <li>
                  自動的に迷惑メールフィルタで迷惑メールとして別フォルダに振り分けられている場合があります。 
                </li>
                <li>
                  メールは「info@xxxxxxxxxx.com」より送信されますので、受信拒否機能を設定されている場合は、ドメイン「xxxxxxxxxxk.com」のメールが受信できるように設定後、改めてお試しください。 
                </li>
                <li>
                  入力していただいたメールアドレスが間違っていたと予想される場合は、お問い合わせ手続きを最初からやり直してください。
                </li>
              </ul>
            </div>
            <p class="pt-4 pull-right"><b-button variant="outline-primary" @click="redirect" class="btn-outline" pill>{{ $t('general_inputs.btn_next') }}</b-button></p>
          </b-form>
        </b-card-body>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
export default {
  name: "NotifyVerifyCode",
  props: {
    formRegisterData: {
      type: [Object],
      default: () => {}
    }
  },
  data() {
    return {
      isSubmitted: false,
    };
  },
  methods: {
    redirect() {
      this.$emit('activeComponent', { user: this.formRegisterData, name: 'VerifyCode' });
    },
  },
};
</script>